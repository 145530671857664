import styled from 'styled-components'
import { Color, Border } from 'atomic'
import { ImgMovedBackgroundProps } from './img-moved-background.component'

export const ImgMovedBackgroundStyled = styled.div<{ height: number }>`
  position: relative;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  width: 100%;
`

export const MovedBackgroundStyled = styled.div<Partial<ImgMovedBackgroundProps>>`
  border-radius: ${Border.Radius};
  background-color: ${props => props.backgroundColor};
  width: ${props => props.backgroundProportionPercentual};
  height: ${props => props.backgroundProportionPercentual};
  position: absolute;
  ${props =>
    props.imgPosition === 'top-left' || props.imgPosition === 'top-right' ? 'bottom: 0' : 'top: 0'};
  ${props =>
    props.imgPosition === 'top-left' || props.imgPosition === 'bottom-left'
      ? 'right: 0'
      : 'left: 0'};
`

export const MovedImgStyled = styled.div<Partial<ImgMovedBackgroundProps>>`
  border-radius: ${Border.Radius};
  background-color: ${Color.Black};
  width: ${props => props.imgProportionPercentual};
  height: ${props => props.imgProportionPercentual};
  position: absolute;
  ${props =>
    props.imgPosition === 'top-left' || props.imgPosition === 'top-right' ? 'top: 0' : 'bottom: 0'};
  ${props =>
    props.imgPosition === 'top-left' || props.imgPosition === 'bottom-left'
      ? 'left: 0'
      : 'right: 0'};
  overflow: hidden;
`
