import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { useClientRectByPassingRef } from 'atomic/obj.custom-hooks/client-rect.hook'
import React, { useRef } from 'react'
import {
  ImgMovedBackgroundStyled,
  MovedBackgroundStyled,
  MovedImgStyled
} from './img-moved-background.style'

export interface ImgMovedBackgroundProps {
  aspectRatio: number
  imgProportionPercentual: string
  backgroundProportionPercentual: string
  imgixImgSrc: string
  imgAlt: string
  imgPosition: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'
  backgroundColor: string
}

const ImgMovedBackground: React.FunctionComponent<ImgMovedBackgroundProps> = props => {
  const wrapperRef = useRef()
  const wrapperRect = useClientRectByPassingRef(wrapperRef)
  const wrapperHeight = Math.max(wrapperRect ? wrapperRect.width * props.aspectRatio : 0, 0)
  return (
    <div ref={wrapperRef}>
      <ImgMovedBackgroundStyled height={wrapperHeight}>
        <MovedBackgroundStyled
          backgroundProportionPercentual={props.backgroundProportionPercentual}
          backgroundColor={props.backgroundColor}
          imgPosition={props.imgPosition}
        />

        <MovedImgStyled
          imgProportionPercentual={props.imgProportionPercentual}
          imgPosition={props.imgPosition}
        >
          <LazyLoadImage
            src={props.imgixImgSrc}
            aspectRatio={props.aspectRatio}
            sizes={'50vw'}
            alt=""
            fitWidth
          />
        </MovedImgStyled>
      </ImgMovedBackgroundStyled>
    </div>
  )
}

export default ImgMovedBackground
