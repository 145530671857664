import { BlackBackground } from 'site/src/components/atm.background/background.styled'
import { BorderRoundWrapper } from 'site/src/components/atm.wrapper/wrapper.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import ImgMovedBackground from 'site/src/components/mol.img-moved-background/img-moved-background.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from 'site/src/context/client-doctor-select'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getClientAndDoctorUrl, isExternalUrl } from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import { getListJsonLd } from 'site/src/components/legacy/mol.seo/build-list-json-ld'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { Query } from 'site/src/data/graphql/graphql-types'
import { Body, Col, Grid, H2, Row } from 'atomic'
import { Button } from 'atomic/atm.button/button.component'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { Separator } from 'atomic/legacy/obj.box'
import ColorFunc from 'color'
import { graphql } from 'gatsby'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export interface NewServicesDetailTemplateProps {
  slug: string
}

const NewServicesDetailTemplate: React.FunctionComponent<PageProps<
  NewServicesDetailTemplateProps,
  Query
>> = props => {
  const cosmicData = props.data.cosmicjsNovosServicos
  const metadata = cosmicData.metadata
  const sections = metadata.novos_negocios.map(item => ({
    title: item.nomeNegocio,
    content: item.descricaoNegocio,
    imgUrl: item.imagemNegocio.imgix_url,
    imgAlt: `Imagem representando o ${item.nomeNegocio}`,
    linkText: 'Visitar página',
    linkTo: item.linkNegocio
  }))
  console.assert(sections.length > 0, 'section count should be greater than 0')

  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[`/${props.pageContext.slug}`] =
    cosmicData.title

  const data = {
    sections,
    title: cosmicData.title,
    seo: cosmicData.metadata.seo
  }
  const siteUrl = props.data.site.siteMetadata.siteUrl
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)

  const themeContext = useContext(ThemeContext)

  return (
    <ClientDoctorSelectContext.Provider
      value={{ clientUrl: clientPath, doctorUrl: doctorPath }}
    >
      <IndexLayout location={props.location}>
        <SEO
          jsonld={getListJsonLd(
            data.sections.map(item => ({
              url: isExternalUrl(item.linkTo)
                ? item.linkTo
                : getCanonicalUrl(
                    siteUrl,
                    appPaths.otherServices.path,
                    item.linkTo
                  )
            }))
          )}
          socialMedia={{
            canonicalUrl: `${props.data.site.siteMetadata.siteUrl}${props.location.pathname}${props.location.search}`,
            title: data.seo.titulo,
            image: data.seo.imagem.url,
            imageAlt: data.seo.imagemAlternativa,
            description: data.seo.descricao
          }}
        />
        <Grid>
          <TitleWithBreadcrumbRow
            title={data.title}
            addtionalDictionary={breadcrumbAdditionalDictionary}
          />

          <Row>
            <Col xs={12} md={5}>
              <ImgMovedBackground
                aspectRatio={0.7}
                imgProportionPercentual={'93%'}
                backgroundProportionPercentual={'50%'}
                imgixImgSrc={data.sections[0].imgUrl}
                imgAlt={data.sections[0].imgAlt}
                imgPosition='bottom-right'
                backgroundColor={ColorFunc(themeContext.color.primary)
                  .lighten(0.9)
                  .string()}
              />
            </Col>
            <Col xs={false} md={1} />

            <TextContentCol
              title={data.sections[0].title}
              content={data.sections[0].content}
              linkTo={data.sections[0].linkTo}
              linkText={data.sections[0].linkText}
              id='section-0'
            />
          </Row>

          <LargeSeparatorRow />
        </Grid>

        {sections[1] && (
          <BlackBackground>
            <Grid>
              <LargeSeparatorRow />
              <Row>
                <TextContentCol
                  title={data.sections[1].title}
                  content={data.sections[1].content}
                  linkTo={data.sections[1].linkTo}
                  linkText={data.sections[1].linkText}
                  light
                  id='section-1'
                />
                <Col xs={false} md={1} />
                <Col xs={12} md={5}>
                  <BorderRoundWrapper>
                    <LazyLoadImage
                      src={data.sections[1].imgUrl}
                      sizes={'50vw'}
                      fitWidth
                      aspectRatio={0.7}
                      alt={data.sections[1].imgAlt}
                    />
                  </BorderRoundWrapper>
                </Col>
              </Row>
              <LargeSeparatorRow />
            </Grid>
          </BlackBackground>
        )}

        {data.sections[2] && (
          <Grid>
            <LargeSeparatorRow />
            <Row>
              <Col xs={12} md={4}>
                <BorderRoundWrapper>
                  <LazyLoadImage
                    src={data.sections[2].imgUrl}
                    sizes={'50vw'}
                    fitWidth
                    aspectRatio={0.7}
                    alt={data.sections[2].imgAlt}
                  />
                </BorderRoundWrapper>
              </Col>
              <Col xs={false} md={1} />
              <TextContentCol
                title={data.sections[2].title}
                content={data.sections[2].content}
                linkTo={data.sections[2].linkTo}
                linkText={data.sections[2].linkText}
                id='section-2'
              />
            </Row>
            <LargeSeparatorRow />
          </Grid>
        )}
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

interface TextContentColProps {
  title: string
  content: string
  linkTo: string
  linkText: string
  light?: boolean
  id: string
}

const TextContentCol: React.FunctionComponent<TextContentColProps> = ({
  id,
  ...props
}) => (
  <Col xs={12} md={6}>
    <Row mb>
      <Col xs={12}>
        <H2 kind={props.light ? 'light' : 'default'}>{props.title}</H2>
        <Body kind={props.light ? 'light' : 'default'}>{props.content}</Body>
      </Col>
    </Row>
    <Row mb>
      <Col xs={12} md={4}>
        <Separator />
        <Button
          id={`button-new-services-${id}`}
          kind='secondary'
          light={props.light}
          to={props.linkTo}
          expanded
          external={isExternalUrl(props.linkTo)}
        >
          {props.linkText}
        </Button>
      </Col>
    </Row>
  </Col>
)

export default NewServicesDetailTemplate

export const query = graphql`
  query NewServicesDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    cosmicjsNovosServicos(slug: { eq: $slug }) {
      id
      title
      content
      slug
      type_slug
      metadata {
        novos_negocios {
          nomeNegocio
          descricaoNegocio
          linkNegocio
          imagemNegocio {
            imgix_url
          }
        }
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
      }
    }
  }
`
